import _global3 from "../internals/global";
import _domIterables from "../internals/dom-iterables";
import _domTokenListPrototype from "../internals/dom-token-list-prototype";
import _arrayForEach from "../internals/array-for-each";
import _createNonEnumerableProperty from "../internals/create-non-enumerable-property";
var _global2 = _global3;
var DOMIterables = _domIterables;
var DOMTokenListPrototype = _domTokenListPrototype;
var forEach = _arrayForEach;
var createNonEnumerableProperty = _createNonEnumerableProperty;
var handlePrototype = function (CollectionPrototype) {
  // some Chrome versions have non-configurable methods on DOMTokenList
  if (CollectionPrototype && CollectionPrototype.forEach !== forEach) try {
    createNonEnumerableProperty(CollectionPrototype, "forEach", forEach);
  } catch (error) {
    CollectionPrototype.forEach = forEach;
  }
};
for (var COLLECTION_NAME in DOMIterables) {
  if (DOMIterables[COLLECTION_NAME]) {
    handlePrototype(_global2[COLLECTION_NAME] && _global2[COLLECTION_NAME].prototype);
  }
}
handlePrototype(DOMTokenListPrototype);
export default {};